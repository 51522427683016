import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from 'common/src/assets/css/style';
import { useTranslation } from 'react-i18next';

const NotFoundPage = ({ pageContext }) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO lang={pageContext.lang} title={t('404: Not found')} />
      <ResetCSS />
      <ErrorSec></ErrorSec>
    </>
  );
};

export default NotFoundPage;
